<template>
  <base-view title="Pagos con Comprobantes Fiscales" icon="file-plus">
    <div>
      <check-authorization :requiresAuthorizations="['indice contratos']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Pagos con Comprobantes Fiscales
            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <search-bar
                placeholder="Buscar por rfc o folio fiscal"
                @filter="(filter) => this.filter = filter"></search-bar>
            </div>
          </b-card-header>

          <b-card-body>
            <x-select
              v-model="periodo"
              dispatchPath="periodoModule/getList"
              getterListPath="periodoModule/getDataList"
              :formatListWith="['id', 'descriptor']"
              defaultText="-- Selecciona un período --"
              noClearButton
              prependText="Período"
            >
            </x-select>

            <renta-list
              class="mt-4"
              v-if="periodo"
              :periodo="periodo"
              :filter="filter"
            >
            </renta-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import RentaList from '@/components/Operaciones/Rentas/RentaList'
import SearchBar from '../../components/Common/Views/SearchBar.vue'
const PERIODO_MODULE = 'periodoModule'

export default {
  name: 'RentasMainView',

  components: {
    RentaList,
    SearchBar
  },

  created () {
    this.init()
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      periodo: null,
      filter: ''
    }
  },

  methods: {
    async init () {
      // Inicializamos secciones
      this.periodoActual()
    },

    async periodoActual () {
      // Obtenemos el período que corresponda al mes y año actual
      const result1 = await this.$store.dispatch(`${PERIODO_MODULE}/getPeriodoActual`)
      if (result1.error) {
        const {
          error,
          message
        } = result1

        this.$notify({
          error,
          message
        }, 'Rentas')
        return
      }
      this.periodo = result1.data.periodo.id
    }
  }
}
</script>

<style scoped>

</style>
